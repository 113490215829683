import React, { lazy, Suspense, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react"
import { SnackbarProvider } from 'notistack';


const Dashboard = lazy(() => import("./pages/Dashboard.js"));
// import Landing from './pages/Landing';
// import Home from './pages/Home';
// import GenePage from './pages/GenePage';
// import DiseasePage from './pages/DiseasePage';
// import PathwayPage from './pages/PathwayPage';
// import ProteomicsSearch from './pages/HomeSubPages/ProteomicsSearch';
// import GeneEnrichment from './pages/HomeSubPages/GeneEnrichment';

function App() {
  //set all init session data for later states
  // useEffect(() => {
  //   sessionStorage.setItem("GeneSelected", JSON.stringify({
  //     "gene_id": 3627,
  //     "tax_id": 9606,
  //     "symbol": "CXCL10",
  //     "locus_tag": "NULL",
  //     "synonyms": "C7|IFI10|INP10|IP-10|SCYB10|crg-2|gIP-10|mob-1",
  //     "db_refs": "MIM:147310|HGNC:HGNC:10637|Ensembl:ENSG00000169245",
  //     "chromosome": "4",
  //     "map_location": "4q21.1",
  //     "description": "C-X-C motif chemokine ligand 10",
  //     "type_of_gene": "protein-coding",
  //     "symbol_from_nomenclature_authority": "CXCL10",
  //     "full_name": "C-X-C motif chemokine ligand 10",
  //     "nomenclature_status": "O",
  //     "other_designations": "C-X-C motif chemokine 10|10 kDa interferon gamma-induced protein|gamma IP10|interferon-inducible cytokine IP-10|protein 10 from interferon (gamma)-induced cell line|small inducible cytokine subfamily B (Cys-X-Cys); member 10|small-inducible cytokine B10",
  //     "modification_date": "2021-08-16T00:00:00.000Z",
  //     "feature_type": "NULL\r"
  //   }))
  // }, [])
  const { isLoading } = useAuth0();
  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loader">
          <div className="inner one"></div>
          <div className="inner two"></div>
          <div className="inner three"></div>
        </div>
      </div>
    )
  }
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: "right", vertical: "bottom"}} autoHideDuration={3000}>
      <div className='App'>

        <Suspense fallback={
          <div className="loading-container">
            <div className="loader">
              <div className="inner one"></div>
              <div className="inner two"></div>
              <div className="inner three"></div>
            </div>
          </div>}
        >
          <Router>
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
    </SnackbarProvider>
  );
}


export default App;
